import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipo-unidad',
  templateUrl: './tipo-unidad.component.html',
  styleUrls: ['./tipo-unidad.component.css']
})
export class TipoUnidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
