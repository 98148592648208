import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CotizacionModel } from '../../models/cotizacion.model';
import { URL_SERVICIOS } from '../../config/config';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CotizacionesService {

  constructor(public http: HttpClient) { }

  cotizacion( cotizacion: CotizacionModel) {
    
    let contesta = 'Contestado';
    let estado = 'Contestado';
    console.log('entre al servicio');
    console.log(cotizacion);
    console.log('fin servicio ');
    if(cotizacion.IdCotizacion == undefined){
      cotizacion.IdCotizacion = -1;
      contesta= 'Sin Contestar';
      estado = 'Pendiente';
      console.log('entre al if');
      console.log(cotizacion.IdCotizacion);
      
    }
    const data = {
      IdCotizacion: cotizacion.IdCotizacion
      ,Unidad: cotizacion.Unidad
      ,Estatus: estado
      ,FechaInicial: cotizacion.FechaInicial
      ,FechaFinal: cotizacion.FechaFinal
      ,LugarOrigen: cotizacion.LugarOrigen
      ,LugarDestino: cotizacion.LugarDestino
      ,Contestacion: contesta
      ,PrecioBase: cotizacion.PrecioBase
      ,Detalle: cotizacion.Detalle
   };

   return this.http.post(`${URL_SERVICIOS}/Service1.svc/Cotizacion`, data)
   .pipe(
     map( (resp: any) => {

       return resp;
     })
   );
 }


 getCotizacion(id?: number) {

  return this.http.post(URL_SERVICIOS +'/Service1.svc/GetCotizacion',{
    IdCotizacion: id
  })
  .pipe(
    map( (resp: any) => {
      const {data} = resp;
      console.log('respuesta inicio');
      console.log(JSON.parse(data));
      console.log('respuesta fin');
      
     return JSON.parse(data);
    }));
}
}
