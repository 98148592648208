export class Usuario {
      IdUsuario: string;
      Usuario: string;
      Pwd: string;
      IdPerfil: string;
      Email: string;
      Activo: string;
      FechaCreacion: string;
      FechaUltimaMod: string;
      NombrePcMod: string;
      IdUsuarioMod: string;
      urlphoto: string;
}





