import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos/productos.service';
@Component({
  selector: 'app-dhasboard',
  templateUrl: './dhasboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DhasboardComponent implements OnInit {


  constructor(public reporteService:ProductosService) { }

  ngOnInit() {
  }

}
