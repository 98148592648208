<!--*****************************************************************************************************-->
<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;  padding-left: 0px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-theme">
                <h4 class="modal-title text-center text-white" id="myLargeModalLabel">Detalle Cliente</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body bg-light">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="card bg-light">
                    
                    <div class="card-body bg-light">
<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
<ul class="list-group">
    <div class="row">
        <li class="list-group-item list-group-item-primary col-12">
            <p class="font-weight-bold text-center">Consignas</p>
        </li>
    </div>
    <div class="row">
        <li class="list-group-item bg-light col-6">
            <p class="font-weight-bold">Nombre de consigna:</p>
            Abarrotera Cerano, S.A. de C.V.
        </li>
        <li class="list-group-item bg-light col-6">
            <p class="font-weight-bold">ID Consigna:</p>
            C10284
        </li>
    </div>
    <div class="row">
        <li class="list-group-item bg-light col-4">
            <p class="font-weight-bold">Direccion:</p>
            Rafael Ramírez 2 , El Rodeo
        </li>
        <li class="list-group-item bg-light col-4">
            <p class="font-weight-bold">CodigoPostal:</p>
            38050
        </li>
        <li class="list-group-item bg-light col-4">
            <p class="font-weight-bold">Ciudad:</p>
            Cerano, Guanajuato.
        </li>
    </div>

    <div class="row">
        <li class="list-group-item bg-light col-12">
            <p class="font-weight-bold text-center">Zona de entrega</p>
            <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=620&amp;height=400&amp;hl=en&amp;q=%20Celaya+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=3df587dfec1ceba27cd3c771b7455e1534ff903b'></script>
        </li>
    </div>
    </ul>
<!-- ---------------------------------------------------------------------------------------------------------------------------------- -->
                    </div>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->


<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <input name="filtroNombre" type="text" class="form-control" placeholder="Buscar cliente..." [(ngModel)]="filtroNombre">
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-right">
                            <button [routerLink]="['/cliente/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-primary">
                                <i class="fa fa-plus"> Agregar Cliente</i>
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped table-bordered animated fadeIn faster" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <td class="w100">IdCliente</td>
                                <td >Cliente</td>
                                <td>Activo</td>
                                <td class="w100 text-nowrap">Acciones</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of cliente | filterName: filtroNombre | paginate: config; ">
                                <td class="text-center">{{f.Clave}}</td>
                                <td>{{f.Nombre}}</td>
                                <td>
                                    <p class="badge badge-success">Activo</p>
                                </td>
                                <td class="text-nowrap text-center">
                                    <button class="btn btn-info mr-1 btn-sm" [routerLink]="['/cliente',f.IdCliente]">                        
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                  <button class="btn btn-warning mr-1 btn-sm" [routerLink]="['/cliente',f.IdCliente]">                        
                                    <i class="fa fa-lock"></i>
                                  </button>
                                  <button  class="btn btn-success btn-sm m-1" data-toggle="modal" data-target=".bs-example-modal-lg">
                                    Detalle cliente
                                </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousp="Anterior" nextp="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>