import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { CotizacionModel } from '../../models/cotizacion.model';
import { CotizacionesService } from '../../services/cotizaciones/cotizaciones.service';

@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.css']
})
export class CotizadorComponent implements OnInit {

  cotizacion: CotizacionModel = new CotizacionModel();
  accionUpdate = false;
  token: string;
  equipoID: string;
  empleadoID: string;

  constructor(private cotizacionesService: CotizacionesService, private route: ActivatedRoute,
    public router: Router) { }

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');
    if (id !== 'nuevo') {
      this.accionUpdate = true;
     /* this.CotizacionesService.getProductoID(this.token, id)
        .subscribe(resp => {
          this.producto = resp['0'];
        });*/
    } else {
      this.accionUpdate = false;
    }
  }


  guardar(form: NgForm) {
 // console.log(form.value);
    if (form.invalid) {
      return ;
    }
    Swal.fire({
      title: 'Espere',
      html: 'Guardando Información...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    

    this.cotizacionesService.cotizacion(form.value)
    .subscribe(resp => {
      console.log('listo');
      Swal.close();
      if (resp['data'] == 'Insert') {
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Solicitud guardada y enviada',
          footer: 'Se contestara a la breveda posible por un administrador'
        });
        this.router.navigateByUrl('/cotizador');
         }
         // tslint:disable-next-line: no-string-literal
      if (resp['data'] === 'Update') {
        Swal.fire({
          icon: 'success',
          title: 'Actualizado',
          text: 'Solicitud Actualizada y enviada',
          footer: 'Solicitud contestada y actualizada para el usuario'
        });
          // this.router.navigateByUrl('/dhasboard');
         }
     }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error al enviar solicitud!',
        footer: 'Intentelo más tarde'
      });

    });
  }

  cargarProductoDisponible() {
    /*this.productoService.getProductoDisponible(this.token, localStorage.getItem('cveEmp'))
    .subscribe( (resp: any) => {
      if (resp['0'].claveProducto != null) {
         this.producto = resp;
      } 
    });*/
  }

  cargarEmpleadoCorporativo() {
    /*this.productoService.getEmpleadoCorporativo(this.token, localStorage.getItem('cveEmp'))
    .subscribe( (resp: any) => {

      if (resp['0'].claveEmpleado != null) {
         this.empleado = resp;
      }
       
    });*/
  }

}