import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from 'src/app/models/usuario.model';
import { URL_SERVICIOS } from '../../config/config';
import { map, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  userToken = '';
  usuario: Usuario = new Usuario();
  public token: any = '';

  constructor(public router: Router, public http: HttpClient) {
     this.leerToken();
     this.leerUsuario();
   }

  renuevaToken() {

    let url = URL_SERVICIOS + '/login/renuevatoken';
    url += '?token=' + this.token;

    return this.http.get( url ).pipe(
                 map( (resp: any) => {

                  this.token = resp.token;
                  localStorage.setItem('token', this.token );
                  return true;
                }),
                  catchError( (err, caught) => (
                  this.router.navigate(['/login']))
                )
                );
  }

   logout() {
     localStorage.removeItem('Clave');
     localStorage.removeItem('Email');
     localStorage.removeItem('IdPerfil');
     localStorage.removeItem('Nombre');
     localStorage.removeItem('urlphoto');
     localStorage.removeItem('_id');
     localStorage.removeItem('Usuario');
     localStorage.removeItem('token');
     localStorage.removeItem('cveEmp');
  }

  login( usuario: Usuario, recordar: boolean) {
    
     if ( recordar === false ) {
      localStorage.removeItem('Usuario');
     } else {
      if (recordar === true ) {
        localStorage.setItem('Usuario', usuario.Usuario);
        }
     }
     
     const authData = {
      EmpId: 1,
      Usuario: usuario.Email,
      Pwd: usuario.Pwd
    };

     return this.http.post(`${URL_SERVICIOS}/Service1.svc/Login`, authData)
    .pipe(
      map( (resp: any) => {
        const {token, Email, IdPerfil, Nombre, Usuario, Urlphoto } = resp;
        this.guardarToken(token, Email, IdPerfil, Nombre, Usuario, Urlphoto);
        return resp;
      })
    );
  }

  private guardarToken(Token: string, Email: string, IdPerfil: string, Nombre: string, Usuario: string, Urlphoto: string) {
    this.userToken = Token;
    localStorage.setItem('cveEmp', '');
    localStorage.setItem('_id', '');
    localStorage.setItem('urlphoto', '');
    localStorage.setItem('token', Token);
    localStorage.setItem('Email', Email);
    localStorage.setItem('IdPerfil', IdPerfil);
    localStorage.setItem('Nombre', Nombre);
    localStorage.setItem('Usuario', Usuario);
    localStorage.setItem('urlphoto', Urlphoto);
  }


  leerToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  leerUsuario() {
    if (localStorage.getItem('usuario') && localStorage.getItem('urlphoto')) {
      this.usuario.Usuario = localStorage.getItem('Usuario');
      this.usuario.urlphoto = localStorage.getItem('urlphoto');
      this.usuario.IdPerfil = localStorage.getItem('IdPerfil');
      this.usuario.Email = localStorage.getItem('Email');
    } else {
       this.usuario = null;
      }
    return this.usuario;
  }

  estaAutenticado(): boolean {
    return (this.userToken.length > 5) ? true : false;
  }
}
