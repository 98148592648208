<app-head-estadistico
[texto] = texto
[icono] = icon
[numeros] = numeros
></app-head-estadistico>

<div class='row animated fadeIn'>
    <div class="col-12">
        <div class="card">


            <div class="card-body">
                <div class="card">
                    <div class="row col-sm-12">
                        <div class="col-sm-12">
                            <input #input (keyup)="buscarCotizacion( input.value )" name="termino" type="text" class="form-control" placeholder="Buscar cotizacion..">
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Cliente</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Unidad</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Estatus</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha Incial</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha Final</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Origen</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Destino</p>
                                </th>
                                <th>
                                <p class="font-weight-bold text-white">Acción</p>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of cotizacion | paginate: config; let i = index">
                                <td> Sociedad Transportista bajio SA DE CV </td>
                                <td>{{ c.Unidad | lowercase }}</td>
                                <td>
                                    <p *ngIf="c.Estatus=='Pendiente'" class="badge badge-warning">Pendiente</p>
                                    <p *ngIf="c.Estatus=='Contestado'" class="badge badge-success">Contestado</p>
                                    <p *ngIf="c.Estatus=='Cancelada'" class="badge badge-danger">Cancelada</p>
                                 </td>
                                <td>{{ c.FechaInicial | date:'fullDate' }}</td>
                                <td>{{ c.FechaFinal   | date:'fullDate'}} </td>
                                <td>{{ c.LugarOrigen }} </td>
                                <td>{{ c.LugarDestino }} </td>
                                <td>
                                    <button class="btn btn-warning mr-1" [routerLink]="['/cotizadorOper',c.IdCotizacion]">                        
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                  <button (click)="detalleInfo(c.IdCotizacion)" class="btn btn-success m-1" data-toggle="modal" data-target=".bs-example-modal-lg">        
                                    <i class="fa fa-eye"></i>
                                    </button>
                                   <!--button class="btn btn-danger" (click)="borrarCliente( f ,i)">
                                    <i class="fa fa-trash"></i>
                                    </button
                                   -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>