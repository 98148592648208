import { Component, OnInit } from '@angular/core';
import { CotizacionModel } from 'src/app/models/cotizacion.model';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';

@Component({
  selector: 'app-cotizaciones-operador',
  templateUrl: './cotizaciones-operador.component.html',
  styleUrls: ['./cotizaciones-operador.component.css']
})
export class CotizacionesOperadorComponent implements OnInit {

  cotizacion: CotizacionModel[] = [];//unidad
  cotizacionID = new CotizacionModel();
  cargando = false;
  result = false;
  config: any;
  checkFactura: string;
  texto = ['Cotizaciones Registradas', 'Cotizaciones Confirmadas', 'Cotizaciones Pendientes'];
  icon = ['fas fa-file-pdf-o', 'fas fa-file-pdf-o', 'fas fa-file-pdf-o'];
  numeros: number[] = [0, 0, 0];
  constructor(private cotizacionesService: CotizacionesService) { }

  ngOnInit() {
    this.numeros = [0, 0, 0];
    this.checkFactura = '';
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.cargarCotizaciones();
   // this.cargarProductoEstadistico();
   // this.cargarProductoAsignado();
  }

  buscarCotizacion(termino: string){

    return ;

  }

  cargarCotizaciones() {
    this.cotizacionesService.getCotizacion(-1)
    .subscribe( (resp: any) => {
      if (resp.length>0) {
         this.cotizacion= resp;
         this.numeros = [this.cotizacion.length, 3, 0];
      } else {
        this.result = true;
        this.cargando = false;

      }
      this.cargando = false;
    });
  }

  
  pageChanged(event) {
    this.config.currentPage = event;
  }
 
  detalleInfo(id: string) {
    if (id !== 'nuevo') {
     /* this.productoService.getProductoID(this.token, id)
        .subscribe(resp => {
          this.productoID = resp['0'];
        });*/
    }
  }

}