import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../../config/config';
import { ConfigResponse } from '../../models/configResponse.model';
@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  token = localStorage.getItem('token');
  configResponse : ConfigResponse = new ConfigResponse();

  constructor(private http: HttpClient) { }

  getProductos() {
    return this.http.post(URL_SERVICIOS + '/producto',{
      'token': this.token
    })
    .pipe(
      map( (resp: any) => {
        console.log(resp);
        const {response} = resp;
        
        console.log(response);
       return response;
      }));
  }

  getFamilias() {
    return this.http.post(URL_SERVICIOS + '/producto/familia',{
      'token': this.token
    })
    .pipe(
      map( (resp: any) => {
        console.log(resp);
        const {response} = resp;
        
        console.log(response);
       return response;
      }));
  }


  getAgentes() {
    return this.http.post(URL_SERVICIOS + '/empleado',{
      'token': this.token
    })
    .pipe(
      map( (resp: any) => {
        const {response} = resp;
        
       return response;
      }));
  }

  getAsignaciones() {
    return this.http.post(URL_SERVICIOS + '/empleado/asignacion',{
      'token': this.token
    })
    .pipe(
      map( (resp: any) => {
        const {response} = resp;
       return response;
      }));
  }

  getEmbedConfig(token: string | null) {
    return this.http.get(`http://144.217.76.55:5300/getEmbedToken?token=` + token).pipe(
     map( (resp: any) => {
       const {accessToken, embedUrl, expiry, status} = resp;
       this.configResponse.EmbedToken = accessToken;
       this.configResponse.EmbedUrl = embedUrl[0].embedUrl;
       this.configResponse.Id = embedUrl[0].reportId;

       return  this.configResponse;

     })
   );
 }
}
