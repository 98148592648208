import { Component, OnDestroy } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: []
})
export class BreadcrumbsComponent implements OnDestroy{

  public titulo: string;
  public titulosSubs$: Subscription;

  constructor(private router: Router, private title: Title) {
    this.titulosSubs$ = this.getArgumentoRuta().
      subscribe(data => {
        this.titulo = data.titulo;
        this.title.setTitle(this.titulo);
        console.log('me subscribi');
      });
  }
  ngOnDestroy(): void {
    this.titulosSubs$.unsubscribe();
  }

  getArgumentoRuta() {
    return this.router.events.pipe(
      filter(evento => evento instanceof ActivationEnd),
      filter((evento: ActivationEnd) => evento.snapshot.firstChild === null),
      map((evento: ActivationEnd) => evento.snapshot.data)
    );
  }
}
