import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
      ],
    }
  ];

  menuNegocio: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
      ],
    },
    {
      titulo: 'Catalogos',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Clientes', url: '/clientes' },
        { titulo: 'Personal', url: '/personal' },
        { titulo: 'Unidades', url: '/unidades' },
        { titulo: 'TipoUnidad', url: '/tipoUnidad' },
       // { titulo: 'Articulos', url: '/articulos' },
        //{ titulo: 'Familias', url: '/familias' },
      ],
    },
    {
      titulo: 'Ordenes',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'CotizadorCliente', url: '/cotizadorClientes' },
       // { titulo: 'Pedidos', url: '/pedidos' },

      ],
    },
    {
      titulo: 'Reportes',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Reporte General', url: '/reportes' },
      ],
    }
  ];

  menuCliente: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
      ],
    },
    {
      titulo: 'Cotizador',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Cotizador', url: '/cotizador' },
      ],
    },
    {
      titulo: 'Ordenes',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Pedidos', url: '/pedidos' },
      ],
    }
  ];



  constructor() { }
}
