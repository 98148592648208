<!--*****************************************************************************************************-->
<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;  padding-left: 0px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-theme">
                <h4 class="modal-title text-center text-white" id="myLargeModalLabel">ASIGNACION DE CLIENTES</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body bg-light">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="card bg-light">
                    
                    <div class="card-body bg-light">
<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
<table class="table table-striped table-bordered animated fadeIn faster" width="100%">
    <thead>
        <tr>
            <th>Cliente</th>
            <th>Fecha Asignacion</th>
            <th class="text-nowrap w100">Estatus</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let p of agenteAsignacion">
            <td>{{ p.Cliente }}</td>
            <td>{{ p.FechaAsignacion | date:'fullDate' }}</td>
            <td>
                <p *ngIf="p.Activo==1" class="badge badge-success">Activo</p>
                <p *ngIf="p.Activo==2" class="badge badge-warning"> Baja</p>
            </td>
        </tr>
    </tbody>
</table>
<!-- ---------------------------------------------------------------------------------------------------------------------------------- -->
                    </div>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->




<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <input name="filtroNombre" type="text" class="form-control" placeholder="Buscar personal..." [(ngModel)]="filtroNombre">
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-right">
                            <button type="button" class="btn waves-effect waves-light btn-rounded btn-primary">
                            <!--button [routerLink]="['/cliente/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-primary"-->
                                                       <i class="fa fa-plus"> Agregar personal</i>
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped table-bordered animated fadeIn faster" width="100%">
                        <thead>
                            <tr>
                                <th class="w100">IdAgente</th>
                                <th>Agente</th>
                                <td>Activo</td>
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of agente | paginate: config; ">
                                <td>{{ f.IdAgente }}</td>
                                <td>{{ f.Agente | uppercase }}</td>
                                <td>
                                    <p class="badge badge-success">Activo</p>
                                </td>
                                <td class="text-nowrap text-center">
                                    <button class="btn btn-info mr-1 btn-sm" [routerLink]="['/cliente',f.IdArticulo]">                        
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                  <button class="btn btn-warning mr-1 btn-sm" [routerLink]="['/cliente',f.IdArticulo]">                        
                                    <i class="fa fa-lock"></i>
                                  </button>
                                  <button (click)="CargarAsignaciones(f.IdAgente)" class="btn btn-success btn-sm m-1" data-toggle="modal" data-target=".bs-example-modal-lg">
                                    <i class="fa fa-eye"></i>

                                </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousp="Anterior" nextp="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>