import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroCliente'
})
export class FiltroClientePipe implements PipeTransform {


  transform(clientes: any): any {
    const resultClientes = [];

    for ( const cliente of clientes) {
      if ( cliente.statuscliente === 'activo') {
        resultClientes.push(cliente);
      } else {
       // console.log('esta dado de baja' + cliente.nombre  );
      }
    }
    return resultClientes;
  }

}
