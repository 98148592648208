export class CotizacionModel {
    IdCotizacion: number;
    IdUnidad: string;
    Estatus: string;
    Unidad: string;
    FechaInicial: string;
    FechaFinal: string;
    LugarOrigen: string;
    LugarDestino: string;
    PrecioBase: string;
    Detalle: string;

    constructor() {
    }
}
