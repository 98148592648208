//imports
import { Component, ElementRef, EventEmitter, AfterViewInit, NgZone, Input, Output } from "@angular/core"
import { MapsAPILoader } from '@agm/core';

export interface LatLng {
  constructor(lat: number, lng: number): void;
  lat(): number;
  lng(): number;
}

@Component({
  selector: 'app-ruta',
  templateUrl: './ruta.component.html',
  styleUrls: ['./ruta.component.css']
})
export class RutaComponent implements AfterViewInit {
   
  @Output() zoomChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() mapReady: EventEmitter<any> = new EventEmitter<any>();

  private _mapResolver!: (value: google.maps.Map) => void;    
  private _map!: Promise<google.maps.Map>;
  
  directionsDisplay: any;
  directionsService: any;
  
  constructor(private _elem: ElementRef, private _loader: MapsAPILoader, private _zone: NgZone
  ) {
    this._map =
      new Promise<google.maps.Map>((resolve: (m: any) => void) => { this._mapResolver = resolve; });
  }

  /** @internal */

  ngAfterViewInit() {
   
    this._loader.load().then(() => {
        
      const mapaDiv = this._elem.nativeElement.querySelector('.agm-map-container');
      this.cargarOpcionesMapa(mapaDiv);

      this.directionsService = new google.maps.DirectionsService();
      this.directionsDisplay = new google.maps.DirectionsRenderer();

      this.initializeDirectionRender();        
      this.GeneraRutaVehiculo();

    });                 
    
  }

  private cargarOpcionesMapa(mapaDiv: HTMLElement) {
    this.createMap(mapaDiv, {
      zoomControlOptions: { position: google.maps.ControlPosition.TOP_CENTER },
      mapTypeControl: true,
      mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_CENTER },
      fullscreenControlOptions: { position: google.maps.ControlPosition.TOP_CENTER },
      mapTypeId: google.maps.MapTypeId.ROADMAP,      
    })
      .then(() => this.getNativeMap())      
  }

  createMap(el: HTMLElement, mapOptions: google.maps.MapOptions): Promise<void> {
    return this._loader.load().then(() => {
      const map = new google.maps.Map(el, mapOptions);
      this._mapResolver(map);
      return;
    });
  }

  getNativeMap(): Promise<google.maps.Map> {
    return this._map;
  }

  private async initializeDirectionRender() {
    this.directionsDisplay = new google.maps.DirectionsRenderer();

    var map = await this.getNativeMap();
    this.directionsDisplay.setMap(map);
  }
      
  GeneraRutaVehiculo() {
    var pointIni = { lat: 20.588810, lng: - 100.389885 };
    var pointFin = { lat: 20.659966, lng: -103.349966 };
    
    this.directionsService.route({
      origin: pointIni,
      destination: pointFin,
      travelMode: google.maps.TravelMode.DRIVING
    }, (response: any, status: any) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.directionsDisplay.setDirections(response);        
      }      
    });
    
  }
}
