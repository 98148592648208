<!--*****************************************************************************************************-->
<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;  padding-left: 0px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-theme">
                <h4 class="modal-title text-center text-white" id="myLargeModalLabel">DETALLE PEDIDO</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body bg-light">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="card bg-light">
                    
                    <div class="card-body bg-light">
<!-- --------------------------------------------------------------------------------------------------------------------------------- -->  
<table class="table table-striped table-bordered animated fadeIn faster" width="100%">
    <thead>
        <tr>
            <th class="w100">#</th>
            <th>Platillo</th>
            <th>Complemento</th>
            <th>Cantidad</th>
            <th class="text-nowrap w100">Observaciones</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>Mole verde</td>
            <td>Arroz, tortillas</td>
            <td>4</td>
            <td>La carne aparte</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Mole verde</td>
            <td>Arroz, tortillas</td>
            <td>4</td>
            <td>La carne aparte</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Mole verde</td>
            <td>Arroz, tortillas</td>
            <td>4</td>
            <td>La carne aparte</td>
        </tr>
        <tr>
            <td>4</td>
            <td>Mole verde</td>
            <td>Arroz, tortillas</td>
            <td>4</td>
            <td>La carne aparte</td>
        </tr>
        <tr>
            <td>5</td>
            <td>Mole verde</td>
            <td>Arroz, tortillas</td>
            <td>4</td>
            <td>La carne aparte</td>
        </tr>
        <tr>
            <td>6</td>
            <td>Mole verde</td>
            <td>Arroz, tortillas</td>
            <td>4</td>
            <td>La carne aparte</td>
        </tr>
        <tr>
            <td>7</td>
            <td>Mole verde</td>
            <td>Arroz, tortillas</td>
            <td>4</td>
            <td>La carne aparte</td>
        </tr>
    </tbody>
</table>
<table *ngIf="!cargando" class="table table-striped table-bordered animated fadeIn faster" width="100%">
    <thead>
        <tr>
            <th class="w100">IdPedidoDet</th>
            <th>Codigo</th>
            <th>Articulo</th>
            <th>Precio</th>
            <th>Cantidad</th>
            <th class="text-nowrap w100">Total</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let p of pedidodet | filterName: filtroNombre | paginate: config; ">
            <td>{{p.IdPedidoDet}}</td>
            <td>{{p.Codigo}}</td>
            <td>{{p.Descripcion}}</td>
            <td>{{p.PUnit | currency:'MXN':'symbol-narrow'}}</td>
            <td>{{p.Cantidad}}</td>
            <td>{{p.Total | currency:'MXN':'symbol-narrow' }}</td>
        </tr>
    </tbody>
</table>
<div class="text-center">
    <button class="btn btn-success btn-md m-r-10" (click)="aceptarOrden()" data-dismiss="modal">
        ACEPTAR ORDEN
    </button>
    
    <button class="btn btn-danger btn-md" (click)="rechazarOrden()">
        RECHAZAR ORDEN
    </button>
</div>
<!-- ---------------------------------------------------------------------------------------------------------------------------------- -->
                    </div>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-info waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->
<h3 class="title text-center">CONTROL DE PEDIDOS DEL DIA</h3>
<h2 class=" text-center" style="margin-top:1px;color: #000;font-weight: 500;"> {{now|date:'hh:mm:ss a'}}</h2>
<div class="row">
    <div class="col-md-4">
            <div class="text-center">
                 <h5>PENDIENTES DE CONFIRMAR <span class="label label-success">#2</span></h5>
            </div>
    </div>
    <div class="col-md-4">
            <div class="text-center">
                <h5>CONFIRMADOS EN PREPARACIÓN <span class="label label-warning">#3</span></h5>
            </div>
    </div>
    <div class="col-md-4">
            <div class="text-center">
                <h5>FINALIZADOS Y ENVIADOS <span class="label label-danger">#6</span></h5>
            </div>
    </div>
</div>
	                <div class="row">
	                    <div class="col-md-4">
                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-success">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #18387</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">13</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 11:52 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>

                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-success">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #19387</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">13</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 12:52 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>

	                    </div>
                        
	                    <div class="col-md-4">
                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-warning">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1732</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">19</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 13:31 PM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Revisar Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>

                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-warning">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1727</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">9</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 13:22 PM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Revisar Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>


                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-warning">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1627</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">5</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 13:11 PM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Revisar Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
	    
	                    </div>
	                    <div class="col-md-4">

                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-danger">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1127</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">15</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 09:42 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>

                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-danger">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1127</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">15</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 09:42 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>

                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-danger">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1127</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">15</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 09:42 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-danger">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1127</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">15</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 09:42 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-danger">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1127</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">15</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 09:42 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-danger">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1127</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">15</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 09:42 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                            <div>
                                <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <div class="soc-header border border-secondary bg-danger">
                                                                <h3 class="card-title font-weight-bold text-center text-dark"> Folio #1127</h3>
                                                                    <img src="../../../assets/images/mapa-1.png"  width='100%' height="150px"/>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">Articulos pedidos: <span class="badge badge-light">15</span></p>
                                                                <p class="font-weight-bold text-dark text-center m-t-10">hora de pedido: 09:42 AM</p>
                                                                <div class="col-lg-12 text-center m-b-10">
                                                                    <button class="btn btn-info btn-sm" data-toggle="modal" data-target=".bs-example-modal-lg">
                                                                        <span class="btn-label">
                                                                            <i class="ti-search"></i>
                                                                        </span>
                                                                        Ver Orden
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>

	                    </div>
	                </div>
