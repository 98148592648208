<div class="animated fadeIn faster">
    <hr>
    <div class="row text-right">
        <div class="col">
            <button [routerLink]="['/cotizadorClientes']" class="btn btn-danger">
                <i class="fa fa-arrow-circle-left"></i>
                Regresar
            </button>
        </div>
    </div>
    <div class="row animated fadeIn faster">
        <div class="col">
            <form (ngSubmit)="guardar( f )" #f="ngForm">

                <div class="form-group" *ngIf="accionUpdate">
                    <label> Id Cotizacion</label>
                    <input type="text" class="form-control" name="IdCotizacion" [(ngModel)]="cotizacion.IdCotizacion"
                        placeholder="Id Cotizacion" disabled="disabled">
                    <small class="form-text text-muted">Este campo es autogenerado</small>
                </div>


                <div class="form-group" *ngIf="accionUpdate">
                    <label>Precio Base</label>
                    <input type="text" class="form-control" name="PrecioBase" [(ngModel)]="cotizacion.PrecioBase"
                        placeholder="Precio base">
                </div>

                <div class="form-group" *ngIf="accionUpdate">
                <label>Detalle</label>
                <textarea class="form-control" rows="5" name="Detalle" [(ngModel)]="cotizacion.Detalle" placeholder="Detalle de cotización"></textarea>
                </div>
        
                <hr>
                <div class="form-group text-center">
                    <button type="submit" class="btn btn-themecolor w-25"><i class="fa fa-save"></i> Enviar respuesta de cotizacion</button>
                </div>
            </form>
        </div>
    </div>
</div>