<div class="animated fadeIn faster">
    <hr>
    <div class="row text-right">
        <div class="col">
            <button [routerLink]="['/cotizador']" class="btn btn-danger">
                <i class="fa fa-arrow-circle-left"></i>
                Regresar
            </button>
        </div>
    </div>
    <div class="row animated fadeIn faster">
        <div class="col">
            <form (ngSubmit)="guardar( f )" #f="ngForm">

                <div class="form-group" *ngIf="accionUpdate">
                    <label> Id Cotizacion</label>
                    <input type="text" class="form-control" name="IdCotizacion" [(ngModel)]="cotizacion.IdCotizacion"
                        placeholder="Id Cotizacion" disabled="disabled">
                    <small class="form-text text-muted">Este campo es autogenerado</small>
                </div>

                <div class="form-group">
                    <label>Unidad a solicitar</label>
                    <select class="form-control" name="Unidad" [(ngModel)]="cotizacion.Unidad"
                        required>
                        <option value="Nissan Np 300 2009 con Caja Seca Reno"> Nissan Np 300 2009 con Caja Seca Reno</option>
                        <option value="Nissan Np 300 2012 con Caja Seca Frimax 30280 6830 03"> Nissan Np 300 2012 con Caja Seca Frimax 30280 6830 03</option>
                        <option value="Nissan Np 300 2012 con Caja Seca Frimax 30280 6830 01"> Nissan Np 300 2012 con Caja Seca Frimax 30280 6830 01</option>
                        <option value="Nissan Np 300 2016 con Caja Seca Reno R05441"> Nissan Np 300 2016 con Caja Seca Reno R05441</option>
                        <option value="Sterling 360 2008 con Caja Seca Moncada"> Sterling 360 2008 con Caja Seca Moncada</option>
                        <option value="Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 01"> Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 01</option>
                        <option value="Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 02"> Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 02</option>
                        <option value="Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 03"> Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 03</option>
                        <option value="Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 04"> Dodge Ram 4000 2012 con Caja Seca Frimax 30288 6889 04</option>
                    </select>
                </div>
                <div class="form-group" class="row col-md-12">
                    <div class="form-group" class="col-md-6">
                    <label>Fecha Inicial</label>
                    <input type="date" class="form-control" name="FechaInicial" [(ngModel)]="cotizacion.FechaInicial"
                        placeholder="Fecha inicial">
                </div>
                <div class="form-group" class="col-md-6">
                    <label>Fecha final</label>
                    <input type="date" class="form-control" name="FechaFinal" [(ngModel)]="cotizacion.FechaFinal"
                        placeholder="Fecha final">
                </div>
            </div>
                <div class="form-group" class="col-md-12">
                    <label>Lugar Origen</label>
                    <select class="form-control" name="LugarOrigen" [(ngModel)]="cotizacion.LugarOrigen"
                        required>
                        <option value="Guadalajara"> Guadalajara</option>
                        <option value="Querétaro"> Querétaro</option>
                        <option value="Mexico"> Mexico</option>
                        <option value="Monterrey"> Monterrey</option>
                        <option value="Zacatecas"> Zacatecas</option>
                        <option value="Laredo TX"> Laredo TX</option>
                    </select>
                </div>
                <div class="form-group" class="col-md-12">
                    <label>Lugar Destino</label>
                    <select class="form-control" name="LugarDestino" [(ngModel)]="cotizacion.LugarDestino"
                        required>
                        <option value="Guadalajara"> Guadalajara</option>
                        <option value="Querétaro"> Querétaro</option>
                        <option value="Mexico"> Mexico</option>
                        <option value="Monterrey"> Monterrey</option>
                        <option value="Zacatecas"> Zacatecas</option>
                        <option value="Laredo TX"> Laredo TX</option>
                    </select>
                </div>
                <hr>
                <div class="form-group text-center">
                    <button type="submit" class="btn btn-themecolor w-25"><i class="fa fa-save"></i> Enviar Solicitud de cotizacion</button>
                </div>
            </form>
        </div>
    </div>
</div>