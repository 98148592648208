import { Component, OnInit } from '@angular/core';
import { IReportEmbedConfiguration, models, service } from 'powerbi-client';
import { ProductosService } from 'src/app/services/productos/productos.service';
import { ConfigResponse } from '../../models/configResponse.model';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  reportConfigResponse: ConfigResponse = new ConfigResponse();
  // Overall status message of embedding
  displayMessage = 'The report is bootstrapped. Click Embed Report button to set the access token.';
  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };
  // CSS Class to be passed to the wrapper
  // Hide the report container initially
  reportClass = 'report-container';
  // Track Report embedding status
  isEmbedded = false;
  token = localStorage.getItem('token');
  constructor(public reporteService:ProductosService) { }

  ngOnInit() {
    this.embedReport();
  }

  async embedReport(): Promise<void> {
    // Get the embed config from the service and set the reportConfigResponse
    try {
          this.reportConfigResponse = await this.reporteService.getEmbedConfig(this.token).toPromise();
        } catch (error) {
                          console.error(this.displayMessage);
                          return;
                        }
    // Update the reportConfig to embed the PowerBI report
    this.reportConfig = {
      ...this.reportConfig,
      id: this.reportConfigResponse.Id,
      embedUrl:this.reportConfigResponse.EmbedUrl,
      accessToken: this.reportConfigResponse.EmbedToken,
    };
    // Update the display message
    this.displayMessage = 'Access token is successfully set. Loading Power BI report.';
  }


eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
  ['loaded', () => console.log('Report has loaded')],
  [
    'rendered',
    () => {
      // Set displayMessage to empty when rendered for the first time
      if (!this.isEmbedded) {
        this.displayMessage = 'Use the buttons above to interact with the report using Power BI Client APIs.';
      }
      // Update embed status
      this.isEmbedded = true;
    },
  ],
  [
    'error',
    (event?: service.ICustomEvent<any>) => {
      if (event) {
        console.error(event.detail);
      }
    },
  ],
  ['visualClicked', () => console.log('visual clicked')],
  ['pageChanged', (event) => console.log(event)],
]);
}
