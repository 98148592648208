import { Component, OnInit } from '@angular/core';
import { AgenteAsignacionModel, AgenteModel } from 'src/app/models/agente.model';
import { ProductosService } from 'src/app/services/productos/productos.service';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {

  agente: AgenteModel[] = [];
  agenteAsignacion: AgenteAsignacionModel[] = [];
  filtroNombre: any;
  cargando = false;
  result = false;
  config: any;
  constructor(public productosService: ProductosService) { }

  pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.filtroNombre = '';
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };
    this.cargando = true;
    this.CargarAgentes();
  }

  CargarAgentes() {
    this.productosService.getAgentes()
    .subscribe( (resp: any) => {
      if (resp['0'] != null) {
         this.agente= resp;
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  CargarAsignaciones(IdAgente: number) {
    this.productosService.getAsignaciones()
    .subscribe( (resp: any) => {
      if (resp['0'] != null) {
         this.agenteAsignacion= resp;
         console.log(this.agenteAsignacion);
      } 
    });
  }

}