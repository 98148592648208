<!--*****************************************************************************************************-->
<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true" style="display: none;  padding-left: 0px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-theme">
                <h4 class="modal-title text-center text-white" id="myLargeModalLabel">Detalle de producto</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body bg-light">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="card bg-light">
                    <img src='http://www.asieselmexicano.com/images/productos/thumbs/8_maiz-blanco-pozolero_imagen.png' class="align-self-center" width='162px' height='162px'>
                    <div class="card-body bg-light">
                        <!-- --------------------------------------------------------------------------------------------------------------------------------- -->
                        <ul class="list-group">
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">Descripcion general</p>
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-12">
                                    El Mexicano tiene para ti su maíz blanco en presentación de 3.01 kg. Maíz pozolero con una textura perfecta que hará de tu receta de pozole única gracias a su consistencia y sabor. ¡Lleva el sabor de México a tu mesa!<br>
                                    • Maíz precocido<br>
                                    • Certificación Kosher<br>
                                    Cuando solicitas tu despensa a domicilio en nuestra tienda en línea tienes la garantía de recibir productos de calidad que se adaptan a tu estilo de vida.
                                </li>                                
                            </div>

                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">Características</p>
                                </li>
                                <li class="list-group-item bg-light col-12">
                                    <p class="font-weight-bold">Contenido del Empaque</p>
                                    Lata con 3.01 kg
                                </li>
                                <li class="list-group-item bg-light col-12">
                                    <p class="font-weight-bold">Ingredientes</p>
                                    Maíz blanco, agua y sal yodada, no contiene conservadores.
                                </li>
                                <li class="list-group-item bg-light col-12">
                                    <p class="font-weight-bold">Contenido en Masa Drenada</p>
                                   -
                                </li>
                                <li class="list-group-item bg-light col-12">
                                    <p class="font-weight-bold">Tipo de empaque</p>
                                    Lata
                                </li>
                            </div>
                        </ul>
                        <!-- ---------------------------------------------------------------------------------------------------------------------------------- -->
                    </div>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->


<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <input name="filtroNombre" type="text" class="form-control" placeholder="Buscar Articulo..."
                [(ngModel)]="filtroNombre">
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-right">
                            <button type="button" class="btn waves-effect waves-light btn-rounded btn-primary">
                                <!--button [routerLink]="['/cliente/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-primary"-->
                                <i class="fa fa-plus"> Agregar Articulo</i>
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped table-bordered animated fadeIn faster"
                        width="100%">
                        <thead>
                            <tr>
                                <th class="w100">IdArticulo</th>
                                <th>Familia</th>
                                <th>Codigo</th>
                                <th>Descripcion</th>
                                <th>Estatus</th>
                                <th>Presentacion</th>
                                <th>PUnit</th>
                                <th>KgCaja</th>
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of producto | paginate: config; ">
                                <td>{{ f.IdArticulo }}</td>
                                <td>{{ f.Familia }}</td>
                                <td>{{ f.Codigo }}</td>
                                <td>{{ f.Descripcion | uppercase }}</td>
                                <td>
                                    <p class="badge badge-success">Activo</p>
                                </td>
                                <td>{{ f.Presentacion | uppercase }}</td>
                                <td>{{ f.PUnit }}</td>
                                <td>{{ f.KgCaja }}</td>
                                <td class="text-nowrap text-center">
                                    <button class="btn btn-info mr-1 btn-sm" [routerLink]="['/cliente',f.IdArticulo]">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                    <button class="btn btn-warning mr-1 btn-sm"
                                        [routerLink]="['/cliente',f.IdArticulo]">
                                        <i class="fa fa-lock"></i>
                                    </button>
                                    <button class="btn btn-success btn-sm m-1" data-toggle="modal"
                                        data-target=".bs-example-modal-lg">
                                        Detalle producto
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true"
                            directionLinks="true" previousp="Anterior" nextp="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false"
                    class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>