import { Component, OnInit } from '@angular/core';
import { ProductoModel } from 'src/app/models/producto.model';
import { ProductosService } from 'src/app/services/productos/productos.service';

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.css']
})
export class UnidadesComponent implements OnInit {

  producto: ProductoModel[] = [];
  filtroNombre: any;
  cargando = false;
  result = false;
  config: any;
  id = 'nuevo';
  baja: number;
  activo: number;
  numeros: any[];
  texto = ['Registrados', 'Activos', ' Bajas'];
  icon = ['fas fa-user', 'fas fa-user', 'fas fa-user'];

  constructor(public productosService: ProductosService) {
   }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.filtroNombre = '';
    this.numeros = [0, 0, 0];
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };
    this.cargando = true;
    this.CargarProductos();
  }

  CargarProductos() {
    this.productosService.getProductos()
    .subscribe( (resp: any) => {
      console.log(resp);
      console.log(resp);
      if (resp['0'] != null) {
         this.producto= resp;
         this.numeros = [this.producto.length, this.producto.length, 0];
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

}