import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CotizacionModel } from 'src/app/models/cotizacion.model';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cotizador-operador',
  templateUrl: './cotizador-operador.component.html',
  styleUrls: ['./cotizador-operador.component.css']
})
export class CotizadorOperadorComponent implements OnInit {


  cotizacion: CotizacionModel = new CotizacionModel();
  accionUpdate = false;
  token: string;
  equipoID: string;
  empleadoID: string;

  constructor(private cotizacionesService: CotizacionesService, private route: ActivatedRoute,
    public router: Router) { }

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');
    if (id !== 'nuevo') {
      this.accionUpdate = true;
      this.cotizacionesService.getCotizacion(Number(id))
        .subscribe(resp => {
          this.cotizacion = resp['0'];
         // this.cotizacion.IdCotizacion = Number(id);
          console.log('INICIAL');
          console.log(this.cotizacion);
          console.log('FINAL');
        });
    } else {
      this.accionUpdate = false;
    }
  }


  guardar(form: NgForm) {
    if (form.invalid) {
      return ;
    }

    Swal.fire({
      title: 'Espere',
      html: 'Guardando Información...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    });
   
    this.cotizacion.PrecioBase = form.value.PrecioBase;
    this.cotizacion.Detalle = form.value.Detalle;

    this.cotizacionesService.cotizacion(this.cotizacion)
    .subscribe(resp => {
      console.log('listo');
      Swal.close();
      if (resp['data'] == 'Insert') {
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Solicitud guardada y enviada',
          footer: 'Se contestara a la breveda posible por un administrador'
        });
        this.router.navigateByUrl('/cotizadorClientes');
         }
         // tslint:disable-next-line: no-string-literal
      if (resp['data'] === 'Update') {
        Swal.fire({
          icon: 'success',
          title: 'Actualizado',
          text: 'Solicitud Actualizada y enviada',
          footer: 'Solicitud contestada y actualizada para el usuario'
        });
        this.router.navigateByUrl('/cotizadorClientes');
      }
     }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error al enviar solicitud!',
        footer: 'Intentelo más tarde'
      });

    });
  }

}