import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNameEmpleado'
})
export class FilterNameEmpleadoPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '') { return value; }
    const resultEmpleado = [];
    for (const empleado of value) {
      const termino = empleado.nombre.toLowerCase() + ' ' + empleado.apellido.toLowerCase();
      if (termino.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultEmpleado.push(empleado);
      }
     /* if (empleado.nombre.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultEmpleado.push(empleado);
      }
      if (empleado.apellido.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          resultEmpleado.push(empleado);
        }*/
      /*if (empleado.servicio.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
            resultEmpleado.push(empleado);
          }
      if (empleado.zona.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
            resultEmpleado.push(empleado);
          }*/
    }
    return resultEmpleado;

  }

}
