import { Component, OnInit } from '@angular/core';
import { PedidoDetModel, PedidoModel } from '../../models/pedido.model';
import Swal from 'sweetalert2';
import { OrdenesService } from '../../services/ordenes/ordenes.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {
  now: Date | undefined;
  pedido: PedidoModel[] = [];
  pedidodet: PedidoDetModel[] = [];
  filtroNombre: String = '';
  cargando = false;
  result = false;
  config: any;
  checkFactura: string;
  id = 'nuevo';
  baja: number;
  activo: number;
  constructor(public clienteService: OrdenesService) {
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.filtroNombre = '';
    this.now = new Date();

    setInterval(() => {

      this.now = new Date();

    }, 1000);

    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };
    this.cargando = true;
    this.cargarPedidos();
  }

  cargarPedidos() {
    this.clienteService.getPedidos()
      .subscribe((resp: any) => {
        if (resp['0'] != null) {
          this.pedido = resp;
        } else {
          this.result = true;
        }
        this.cargando = false;
      });
  }

  detalleInfo(IdPedido: string) {
    this.clienteService.getPedidosdet(IdPedido)
      .subscribe((resp: any) => {
        if (resp['0'] != null) {
          this.pedidodet = resp;
          console.log(this.pedidodet);
        } else {
          this.result = true;
        }
        this.cargando = false;
      });
  }

  rechazarOrden() {
    let rechazo = '';
  
    Swal.fire({
      title: 'Estas Seguro?',
      text: "Rechazaras el pedido del cliente!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar Acción',
      confirmButtonText: 'Si, Rechazar!'
    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire({
          title: 'Tipo de rechazo',
          input: 'select',
          inputOptions: {
            resp1: 'Producto no disponible',
            resp2: 'Orden no se completa',
            resp3: 'Insumos insuficientes',
            resp4: 'Sin repartidores disponibles para entrega',
          },
          inputPlaceholder: 'Selecione el motio de rechazo',
          showCancelButton: true,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value === '') {
                rechazo = value;
              }
            }
            )
          }

          //************************************************************************************** */

          //************************************************************************************** */


          /*Swal.fire(
            'Pedido rechazado!',
            'El pedido se ha rechazado y notificado al cliente.',
            'success'
          )*/
        });
      }
  })}



  aceptarOrden() {
    Swal.fire({
      text: `Orden Confirmada`,
      icon: 'success',
      showConfirmButton: false,
      timer: 1500
    }).then(resp => {
      if (resp.value) {
        /* this.clienteService.borrarCliente(cliente.claveCliente)
         .subscribe( () => {
           this.cliente.splice( i, 1 );
           this.cargarClientes();
         });*/
      }
    });
  }
}
