<div class="row">
    <div class="col-lg-4">
        <img src="assets/images/background/finance2.jpg" class="img-landscape" alt="" width="100%" height="100%" />
    </div>
    <div class="col-lg-8">
        <h1>Admin Pro</h1>
        <p class="lead" align="justify">
            Bienvenido a Admin Pro Demo
        </p>
    </div>
</div>