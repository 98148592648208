import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PAGES_ROUTES } from './pages.routes';
import { SharedModule } from "../shared/shared.module"
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

// ng2-charts
import { ChartsModule } from 'ng2-charts';
import { GraficoDonaComponent } from '../components/grafico-dona/grafico-dona.component';
import { GraficoBarComponent } from '../components/grafico-bar/grafico-bar.component';

// pages
import { PagesComponent } from './pages.component';
import { DhasboardComponent } from './dhasboard/dhasboard.component';
import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClienteComponent } from './cliente/cliente.component';

import { PipesModule } from '../pipes/pipes.module';


// Pipe Module
import { FiltroClientePipe } from '../pipes/filtro-cliente.pipe';
import { FilterNamePipe } from '../pipes/filter-name.pipe';
import { FilterNameEmpleadoPipe } from '../pipes/filter-name-empleado.pipe';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PedidosComponent } from './pedidos/pedidos.component';
import { ReportesComponent } from './reportes/reportes.component';
import { PersonalComponent } from './personal/personal.component';
import { UnidadesComponent } from './unidades/unidades.component';
import { CotizacionesClienteComponent } from './cotizaciones-cliente/cotizaciones-cliente.component';
import { TipoUnidadComponent } from './tipo-unidad/tipo-unidad.component';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { CotizacionesOperadorComponent } from './cotizaciones-operador/cotizaciones-operador.component';
import { CotizadorOperadorComponent } from './cotizador-operador/cotizador-operador.component';
import { RutaComponent } from './ruta/ruta.component';

@NgModule({
    declarations: [
        FiltroClientePipe,
        FilterNamePipe,
        FilterNameEmpleadoPipe,
        PagesComponent,
        DhasboardComponent,
        AccoutSettingsComponent,
        GraficoDonaComponent,
        GraficoBarComponent,
        ClientesComponent,
        ClienteComponent,
        PedidosComponent,
        ReportesComponent,
        PersonalComponent,
        UnidadesComponent,
        CotizacionesClienteComponent,
        TipoUnidadComponent,
        CotizadorComponent,
        CotizacionesOperadorComponent,
        CotizadorOperadorComponent,
        RutaComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PAGES_ROUTES,
        BrowserModule,
        ChartsModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        PowerBIEmbedModule,
    ]
})
export class PagesModule { }
