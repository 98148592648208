<!--*****************************************************************************************************-->
<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true" style="display: none;  padding-left: 0px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-theme">
                <h4 class="modal-title text-center text-white" id="myLargeModalLabel">Detalle de producto</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body bg-light">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="card bg-light">
                    <img src='https://sucotizador.com/wp-content/uploads/2020/03/logo.png' class="align-self-center" width='162px' height='162px'>
                    <div class="card-body bg-light">
                        <!-- --------------------------------------------------------------------------------------------------------------------------------- -->
                        <ul class="list-group">
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">PRECIO BASE</p>
                                </li>
                            </div>
                            <div class="row text-center">
                                <li class="list-group-item bg-light col-12">
                                    <h1>$35,000 MXN</h1>
                                </li>
                            </div>

                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">DETALLE</p>
                                </li>
                                <li class="list-group-item bg-light col-12">
                                    <p class="font-weight-bold">El pago de realizarse antes de enviar unidad al origen señalado</p>
                                </li>
                            </div>

                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">DETALLE RUTA</p>
                                </li>
                                <li class="list-group-item bg-light col-12 text-center">
                                    <button class="btn btn-warning btn-md m-r-20"  (click)="openRuta()" data-dismiss="modal">
                                        REVISAR RUTA
                                    </button>
                                </li>
                            </div>

                            <div class="text-center">
                                <br><br>
                                <button class="btn btn-success btn-md m-r-20" data-dismiss="modal">
                                    ACEPTAR Y CONFIRMAR COTIZACION
                                </button>
                                <br>
                                <br>
                                <button class="btn btn-danger btn-md">
                                    CANCELAR SOLICITUD
                                </button>
                            </div>

                        </ul>
                        <!-- ---------------------------------------------------------------------------------------------------------------------------------- -->
                    </div>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->


<!--**********************************************************************************************************-->


<app-head-estadistico
[texto] = texto
[icono] = icon
[numeros] = numeros
></app-head-estadistico>

<div class='row animated fadeIn'>
    <div class="col-12">
        <div class="card">


            <div class="card-body">
                <div class="card">
                    <div class="row col-sm-12">
                        <div class="col-sm-10">
                            <input #input (keyup)="buscarCotizacion( input.value )" name="termino" type="text" class="form-control" placeholder="Buscar cotizacion..">
                        </div>
                        <div class="col-sm-2">
                            <div class="text-right">
                                <button [routerLink]="['/cotizacion/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-themecolor">
                                                       <i class="fa fa-plus"> Solicitar Cotización</i>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Unidad</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Estatus</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha Incial</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha Final</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Origen</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Destino</p>
                                </th>
                                <th>
                                <p class="font-weight-bold text-white">Acción</p>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of cotizacion | paginate: config; let i = index">
                                <td>{{ c.Unidad | lowercase }}</td>
                                <td>
                                    <p *ngIf="c.Estatus=='Pendiente'" class="badge badge-warning">Pendiente</p>
                                    <p *ngIf="c.Estatus=='Contestado'" class="badge badge-success">Contestado</p>
                                    <p *ngIf="c.Estatus=='Cancelada'" class="badge badge-danger">Cancelada</p>
                                 </td>
                                <td>{{ c.FechaInicial | date:'fullDate' }}</td>
                                <td>{{ c.FechaFinal   | date:'fullDate'}} </td>
                                <td>{{ c.LugarOrigen }} </td>
                                <td>{{ c.LugarDestino }} </td>
                                <td>
                                    <!--button class="btn btn-danger mr-1" [routerLink]="['/cotizacion',c.IdCotizacion]">                        
                                    <i class="fa fa-trash"></i>
                                  </button-->
                                  <button *ngIf="c.Estatus=='Contestado'" (click)="detalleInfo(c.IdCotizacion)" class="btn btn-success m-1" data-toggle="modal" data-target=".bs-example-modal-lg">        
                                    <i class="fa fa-eye"></i>
                                    </button>
                                    <button *ngIf="c.Estatus=='Pendiente'" class="btn btn-light m-1" >        
                                        <i class="fas fa-eye-slash"></i>
                                        </button>
                                   <!--button class="btn btn-danger" (click)="borrarCliente( f ,i)">
                                    <i class="fa fa-trash"></i>
                                    </button
                                   -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>