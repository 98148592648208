import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs);

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Rutas
import { AppRoutingModule } from './app-routing.module';
// Modulos
import { PagesModule } from './pages/pages.module';
// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Importaciòn de interceptor
import { AuthInterceptorService } from './auth-interceptor.service';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAs08G7Bw2R3rt8xN5o5pubHmNwypiC7-k',
      libraries: ["places", "directions"]
    }),
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es-MX' },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
