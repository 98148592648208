import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class OrdenesService {
  token = localStorage.getItem('token');
  totalClientes = 0;
  constructor( private http: HttpClient ) { }

  getPedidos() {
    return this.http.post(URL_SERVICIOS + '/producto/pedido',{
      'token': this.token
    })
    .pipe(
      map( (resp: any) => {
        const {response} = resp;
        
       return response;
      }));
  }

  getPedidosdet(IdPedido: string) {
    return this.http.post(URL_SERVICIOS + '/producto/pedidodet',{
      'token': this.token,
      'IdPedido': IdPedido
    })
    .pipe(
      map( (resp: any) => {
        const {response} = resp;
        
       return response;
      }));
  }
}
