import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClienteModel } from 'src/app/models/cliente.model';
import { URL_SERVICIOS } from '../../config/config';
@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  cliente: ClienteModel;
  totalClientes = 0;
  base64: string;
  imagen: any;
  token = localStorage.getItem('token');
  constructor( private http: HttpClient) { 
  }

  getClientes(id?: number) {
    
 
    const headers = new HttpHeaders({'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.token}`});

    /*const header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.token}`)
    }*/ 

    return this.http.post(URL_SERVICIOS +'/Service1.svc/Clientes',{
      IdCliente: id
    })
    .pipe(
      map( (resp: any) => {
        const {data} = resp;
        console.log('respuesta inicio');
        console.log(JSON.parse(data));
       // console.log(JSON.parse(resp));
        console.log('respuesta fin');
        
       return JSON.parse(data);
      }));
  }

  getClienteServicios(token: string, cveEmp: string, tipoRazonSocial: string) {
    return this.http.post(URL_SERVICIOS + '/servicio?token=' + token,
    {
      claveEmpresa: cveEmp,
      razonSocial: tipoRazonSocial
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getClientID(id: string) {
    return this.http.post(URL_SERVICIOS + '/cliente/clienteID?token=' + this.token,
    {
      id,
      token: this.token
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getClienteServicio(token: string, termino: string, cveEmp: string, tipoRazonSocial: string) {
    return this.http.post(URL_SERVICIOS + '/servicio/buscar?token=' + token,
    {
      termino,
      claveEmpresa: cveEmp,
      razonSocial: tipoRazonSocial
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getDetalleServicio(token: string, termino: string, tipoRazonSocial: string) {
    return this.http.post(URL_SERVICIOS + '/servicio/buscarDetalle?token=' + token,
    {
      termino,
      razonSocial: tipoRazonSocial
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getVacanteCliente(token: string, termino: string, cveEmp: string, tipoRazonSocial: string) {
    return this.http.post(URL_SERVICIOS + '/servicio/vacante/buscar?token=' + token,
    {
      termino,
      claveEmpresa: cveEmp,
      razonSocial: tipoRazonSocial
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }


  borrarCliente(id: number) {
    return this.http.post(`${URL_SERVICIOS}/deleteCliente.php`,
    {
        id,
        token: this.token,
        cveEmp : localStorage.getItem('cveEmp')
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      })
    );
  }

  crearCliente( cliente: ClienteModel, token: string) {
  /*  const createFact = {
      nombreEmpresa: cliente.nombreEmpresa,
      rfc: cliente.rfc,
      telefono: cliente.telefono,
      correoEletronico: cliente.correoEletronico,
      statuscliente: cliente.statuscliente,
      fechaIngreso: cliente.fechaIngreso,
      token,
      cveEmp: localStorage.getItem('cveEmp')
    };
    return this.http.post(URL_SERVICIOS + '/cliente/insert?token=' + token, createFact)
    .pipe(
      map( (resp: any) => {
        return cliente;
      })
    );*/
  }

  actualizarCliente( cliente: ClienteModel, token: string ) {
  /*  const updateClient = {
      id: cliente.claveCliente,
      identificador: cliente.identificador,
      rfc: cliente.rfc,
      telefono: cliente.telefono,
      email: cliente.correoEletronico,
      status: cliente.statuscliente,
      fechaIngreso: cliente.fechaIngreso,
      nombreEmpresa: cliente.nombreEmpresa,
      nombreComercial: cliente.nombreComercial
    };
    return this.http.put(`${ URL_SERVICIOS }/cliente/update?token=` + token, updateClient);
  */}

 /* actualizarVacante( vacante: VacanteModel, token: string ) {
    const updateClient = {
      id: vacante.claveVacante,
      servicio: vacante.servicio,
      puesto: vacante.puesto,
      turno: vacante.turno,
      zona: vacante.zona
    };
    return this.http.put(`${ URL_SERVICIOS }/servicio/vacante/update?token=` + token, updateClient);
  }*/

  /*crearVacante( vacante: VacanteModel, token: string) {
    const createVacamte = {
      cliente: vacante.nombreEmpresa,
      servicio: vacante.servicio,
      puesto: vacante.puesto,
      fechaLimite: vacante.fechaLimite,
      turno: vacante.turno,
      cveEmp: localStorage.getItem('cveEmp'),
      zona: vacante.zona,
      token
    };
    return this.http.post(`${ URL_SERVICIOS }/servicio/vacante/create?token=` + token, createVacamte)
    .pipe(
      map( (resp: any) => {
        vacante.claveVacante = resp.response;
        return vacante;
      })
    );
  }*/

   cambiarImagen( archivo: File, claveCliente: string, token: string ) {
    console.log(archivo);
    const formData = new FormData();
    const xhr = new XMLHttpRequest();
    const hdrs = new HttpHeaders({ 'Content-Type': 'multipart/form-data'});
    const options = {
      headers: hdrs
    };

    formData.append( 'cliente', archivo, claveCliente );

    return this.http.post(`${ URL_SERVICIOS }/cliente/updateLogo?token=` + token, formData);
  }

   download(id: string, tipo: string) {
    const claveID = {
      id,
      servicio: tipo,
      token: this.token
    };
    return  this.http.post(`${ URL_SERVICIOS }/reporte/downloads?token=` + this.token, {claveID}, { responseType: 'blob'});

  }

  updateReport(id: string) {

    return this.http.post(URL_SERVICIOS + '/reporte/reporteFactura?token=' + this.token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }
}
