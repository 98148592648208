import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  usuario: string;
  departamento: string;
  imagen: string;
  menuSis: string;
  // tslint:disable-next-line: variable-name
  constructor( public _sidebar: SidebarService, private usuarioService: UsuarioService, public router: Router) {}

  ngOnInit() {
    this.usuarioData();
    this.obtenerMenu();
  }

  public usuarioData() {
    this.usuario = localStorage.getItem('Usuario');
    this.departamento = localStorage.getItem('Nombre');
    this.imagen = localStorage.getItem('urlphoto');
}

  salir() {
     this.usuarioService.logout();
     this.router.navigateByUrl('/login');

  }

  obtenerMenu() {

    if ( localStorage.getItem('Nombre') === 'Administrador') {
      this.menuSis = this._sidebar.menuNegocio;
     } else {
           if ( localStorage.getItem('Nombre') === 'Cliente') {
             this.menuSis = this._sidebar.menuCliente;
           } else {                    
                        this.menuSis = this._sidebar.menuCliente;
                        }
               }
           }
}
