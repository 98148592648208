<div class="animated fadeIn faster">
    <hr>
    <div class="row text-right">
        <div class="col">
            <button [routerLink]="['/clientes']" class="btn btn-danger">
                               <i class="fa fa-arrow-circle-left"></i>
                                Regresar
                            </button>
        </div>
    </div>
    <div class="row animated fadeIn faster">
        <div class="col">
            <form (ngSubmit)="guardar( f )" #f="ngForm">
                <div class="form-group">
                    <label> Id Cliente</label>
                    <input type="text" class="form-control" name="IdCliente" [(ngModel)]="cliente.IdCliente" placeholder="Id cliente" disabled="disabled">
                    <small class="form-text text-muted">Este campo es autogenerado</small>
                </div>
                <div class="form-group">
                    <label> Nombre de Cliente</label>
                    <input type="text" class="form-control" name="Nombre" [(ngModel)]="cliente.Nombre" required placeholder="Nombre de cliente">
                </div>
                <div class="form-group">
                    <label> RFC</label>
                    <input type="text" class="form-control" name="Clave" [(ngModel)]="cliente.Clave" required placeholder="Nombre de cliente">
                </div>
               
                <div class="form-group">
                    <label>Status cliente</label>
                    <select class="form-control" name="Estatus" [(ngModel)]="cliente.Estatus" required>
                                       <option selected="selected" value="activo">activo</option>
                                       <option value="baja">baja</option>
                    </select>
                </div>
                <hr>
                <div class="form-group text-center">
                    <button type="submit" class="btn btn-primary w-25"><i class="fa fa-save"></i> Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>